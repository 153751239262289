import axios from "axios";
import Cookies from "js-cookie";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 24 * 60,
});

service.interceptors.request.use(
  (config) => {
    if (Cookies.get("token")) {
      config.headers["Authorization"] = "Bearer " + Cookies.get("token");
    }

    return config;
  },
  (error) => {
    alert(error); // for debug
    return Promise.reject(error);
  }
);

export default service;
