<template>
  <toast-block />
  <router-view />
</template>

<script>
import ToastBlock from "@/components/toast/index.vue";
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    ToastBlock
  },
  computed: {
    ...mapState(['info']),
  },
  async mounted() {
      await this.$store.dispatch("getInfo");
    }
}
</script>

<style>
@font-face {
  font-family: "Google";
  src: local("Google"),
   url(./assets/fonts/GoogleSans-Medium.ttf) format("medium");
}
#app {
  font-family: Google;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
