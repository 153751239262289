import App from './App.vue';
import { createApp } from 'vue';
import router from "./router";
import store from "./store";
import api from "./utils/api";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { BootstrapVue3, IconsPlugin } from "bootstrap-vue-3";
import VueTheMask from "vue-the-mask";
import "vue-select/dist/vue-select.css";
import 'v-calendar/style.css';
import vSelect from "vue-select";
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import Loading from 'vue3-loading-screen'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const app = createApp(App);

app.use(router);
app.use(VueTheMask);
app.use(BootstrapVue3);
app.use(Toast);
app.use(Loading, {
  bg: '#b9c4bc90',
  slot: `
    <div class="px-5 py-3 bg-gray-800 rounded">
      <h3 class="text-3xl" style="color: #FF7104;"><i class="fas fa-spinner fa-spin"></i> Загрузка...</h3>
    </div>
  `
});
app.component("VSelect", vSelect);
app.use(setupCalendar, {})
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)

app.config.globalProperties.$api = api;
app.config.globalProperties.$url = process.env.VUE_APP_URL;
app.use(store);

app.mount('#app');