import { createStore } from 'vuex';
import api from "../utils/api";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      toastData: null,
      loading: 0,
      leftover: {},
      assortments: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      assortment: {},
      moderations: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      contracts: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      employees: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      sell_objects: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      suppliers: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      refills: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      sells: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      supplies: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      writing_offs: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      inventories: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      leftovers: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      product_for_objects: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      marketplaces: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      info: {
        categories: [],
        subcategories: [],
        cities: [],
      },
      profile: {},
      countries: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      roles: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      cities: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      supplier_categories: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
      merchants: {
        data: [],
        total: 1,
        page: 1,
        pages: 1,
      },
    }
  },
  mutations: {
    setLoading(state, payload = 0) {
      state.loading = payload;
    },
    setToastData(state, payload) {
      state.toastData = payload;
    },
    setInfo(state, payload) {
      state.info = payload;
    },
    setProfile(state, payload) {
      state.profile = payload;
    },
    setLeftover(state, payload) {
      state.leftover = payload;
    },
    setAssortments(state, payload) {
      state.assortments = payload;
    },
    setAssortment(state, payload) {
      state.assortment = payload;
    },
    setModerations(state, payload) {
      state.moderations = payload;
    },
    setContracts(state, payload) {
      state.contracts = payload;
    },
    setEmployees(state, payload) {
      state.employees = payload;
    },
    setSellObjects(state, payload) {
      state.sell_objects = payload;
    },
    setSuppliers(state, payload) {
      state.suppliers = payload;
    },
    setRefills(state, payload) {
      state.refills = payload;
    },
    setSells(state, payload) {
      state.sells = payload;
    },
    setSupplies(state, payload) {
      state.supplies = payload;
    },
    setWritingOffs(state, payload) {
      state.writing_offs = payload;
    },
    setInventories(state, payload) {
      state.inventories = payload;
    },
    setLeftovers(state, payload) {
      state.leftovers = payload;
    },
    setProductForObjects(state, payload) {
      state.product_for_objects = payload;
    },
    setMarketplaces(state, payload) {
      state.marketplaces = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setEmployeeRoles(state, payload) {
      state.roles = payload;
    },
    setSupplierCategories(state, payload) {
      state.supplier_categories = payload;
    },
    setCities(state, payload) {
      state.cities = payload;
    },
    setMerchants(state, payload) {
      state.merchants = payload;
    },
  },
  actions: {
    setLoading: (context, payload) => {
      context.commit('setLoading', payload);
    },
    setToastData: (context, payload) => {
      context.commit('setToastData', payload);
    },
    setInfo: (context, payload) => {
      context.commit('setInfo', payload);
    },
    setProfile: (context, payload) => {
      context.commit('setProfile', payload);
    },
    setLeftover: (context, payload) => {
      context.commit('setLeftover', payload);
    },
    async getInfo({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/info")
        );

        commit("setInfo", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getInfo, сообщите администратору: ",
          err
        );
      }
    },
    async getProfile({ commit }) {
      try {
        const res = await Promise.resolve(
          api.get("/profile")
        );
        commit("setProfile", res.data);
        return res.status;
      } catch (err) {
        return err.response.status;
      }
    },
    async getAssortments({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/assortment", {
            params: payload,
          })
        );

        commit("setAssortments", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getAssortments, сообщите администратору: ",
          err
        );
      }
    },
    async getAssortment({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/assortment/" + payload.id)
        );

        commit("setAssortment", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getAssortment, сообщите администратору: ",
          err
        );
      }
    },
    async getModerations({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/moderation", {
            params: payload,
          })
        );
        commit("setModerations", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getModerations, сообщите администратору: ",
          err
        );
      }
    },
    async getContracts({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/contract", {
            params: payload,
          })
        );
        commit("setContracts", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getContracts, сообщите администратору: ",
          err
        );
      }
    },
    async getEmployees({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/employee", {
            params: payload,
          })
        );
        commit("setEmployees", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getEmployees, сообщите администратору: ",
          err
        );
      }
    },
    async getSellObjects({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/sell_object", {
            params: payload,
          })
        );
        commit("setSellObjects", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getSellObjects, сообщите администратору: ",
          err
        );
      }
    },
    async getSuppliers({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/supplier", {
            params: payload,
          })
        );
        commit("setSuppliers", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getSuppliers, сообщите администратору: ",
          err
        );
      }
    },
    async getRefills({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/refill", {
            params: payload,
          })
        );
        commit("setRefills", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getRefills, сообщите администратору: ",
          err
        );
      }
    },
    async getSells({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/sells", {
            params: payload,
          })
        );
        commit("setSells", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getSells, сообщите администратору: ",
          err
        );
      }
    },
    async getSupplies({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/supplies", {
            params: payload,
          })
        );
        commit("setSupplies", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getSupplies, сообщите администратору: ",
          err
        );
      }
    },
    async getWritingOffs({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/writing_off", {
            params: payload,
          })
        );
        commit("setWritingOffs", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getWritingOffs, сообщите администратору: ",
          err
        );
      }
    },
    async getInventories({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/inventory", {
            params: payload,
          })
        );
        commit("setInventories", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getInventories, сообщите администратору: ",
          err
        );
      }
    },
    async getLeftovers({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/leftover", {
            params: payload,
          })
        );
        commit("setLeftovers", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getLeftovers, сообщите администратору: ",
          err
        );
      }
    },
    async getProductForObjects({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/product_for_object", {
            params: payload,
          })
        );
        commit("setProductForObjects", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getProductForObjects, сообщите администратору: ",
          err
        );
      }
    },
    async getMarketplaces({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/marketplace", {
            params: payload,
          })
        );
        commit("setMarketplaces", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getMarketplaces, сообщите администратору: ",
          err
        );
      }
    },
    async sendSellObject({ commit }, payload) {
      try {
        let url = "/sell_object";
        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        // throw new Error("Внутреняя ошибка сервера: sendSellObject, сообщите администратору");
        return false;
      }
    },
    async sendSupplier({ commit }, payload) {
      try {
        let url = "/supplier/";

        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        // throw new Error("Внутреняя ошибка сервера: sendSellObject, сообщите администратору");
        return false;
      }
    },
    async sendEmployee({ commit }, payload) {
      try {
        let url = "/employee";
        const res = await Promise.resolve(api.post(url, payload))

        if (res.status === 201) {
          return true;
        }
      } catch (err) {
        // throw new Error("Внутреняя ошибка сервера: sendSellObject, сообщите администратору");
        return false;
      }
    },
    async getCountries({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/country", {
            params: payload,
          })
        );

        commit("setCountries", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getCountries, сообщите администратору: ",
          err
        );
      }
    },
    async sendProduct({ commit }, payload) {
      try {
        let url = "/product";
        const res = await Promise.resolve(api.post(url, payload))

        if (res.status === 201) {
          return true;
        }
      } catch (err) {
        // throw new Error("Внутреняя ошибка сервера: sendSellObject, сообщите администратору");
        return false;
      }
    },
    async sendModeration({ commit }, payload) {
      try {
        let url = "/moderation/" + payload.id;
        const res = await Promise.resolve(api.post(url, payload.data))

        if (res.status === 201) {
          return true;
        }
      } catch (err) {
        // throw new Error("Внутреняя ошибка сервера: sendSellObject, сообщите администратору");
        return false;
      }
    },
    async sendCountry({ commit }, payload) {
      try {
        let url = "/country/";

        if (payload.id)
          url += payload.id
        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        return false;
      }
    },
    async sendEmployeeRoles({ commit }, payload) {
      try {
        let url = "/employee_role/";

        if (payload.id)
          url += payload.id
        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        return false;
      }
    },
    async getEmployeeRoles({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/employee_role", {
            params: payload,
          })
        );

        commit("setEmployeeRoles", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getEmployeeRoles, сообщите администратору: ",
          err
        );
      }
    },
    async getSupplierCategories({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/supplier_category", {
            params: payload,
          })
        );

        commit("setSupplierCategories", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getSupplierCategories, сообщите администратору: ",
          err
        );
      }
    },
    async sendSupplierCategory({ commit }, payload) {
      try {
        let url = "/supplier_category/";

        if (payload.id)
          url += payload.id
        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        return false;
      }
    },
    async getCities({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/city", {
            params: payload,
          })
        );

        commit("setCities", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: setCities, сообщите администратору: ",
          err
        );
      }
    },
    async sendCity({ commit }, payload) {
      try {
        let url = "/city/";

        if (payload.id)
          url += payload.id
        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        return false;
      }
    },
    async getMerchants({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/merchant", {
            params: payload,
          })
        );

        commit("setMerchants", res.data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getMerchants, сообщите администратору: ",
          err
        );
      }
    },
    async sendMerchant({ commit }, payload) {
      try {
        let url = "/merchant/";

        if (payload.id)
          url += payload.id
        const res = await Promise.resolve(api.post(url, payload))

        return res.status;
      } catch (err) {
        return false;
      }
    },
  },
  getters: {
    TOAST_DATA: (state) => state.toastData,
    INFO: (state) => state.info,
    loading: (state) => state.loading,
    assortments: (state) => state.assortments,
    assortment: (state) => state.assortment,
    moderations: (state) => state.moderations,
    contracts: (state) => state.contracts,
    employees: (state) => state.employees,
    sell_objects: (state) => state.sell_objects,
    suppliers: (state) => state.suppliers,
    refills: (state) => state.refills,
    sells: (state) => state.sells,
    supplies: (state) => state.supplies,
    writing_offs: (state) => state.writing_offs,
    inventories: (state) => state.inventories,
    leftovers: (state) => state.leftovers,
    leftover: (state) => state.leftover,
    product_for_objects: (state) => state.product_for_objects,
    marketplaces: (state) => state.marketplaces,
    supplier_categories: (state) => state.supplier_categories,
  }
})

export default store;
