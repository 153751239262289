import store from '../store';
import Cookies from "js-cookie";
import { createRouter, createWebHistory } from 'vue-router';

const haveToken = (to, from, next) => {
  if (Cookies.get("token")) next();
  else if (to.path !== "/auth/login") next("/auth/login");
};

const routes = [
  {
    path: "/",
    name: "landing",
    meta: { layout: "header", title: "Лендинг" },
    component: () => import("../views/landing"),
  },
  {
    path: "/contacts",
    name: "contacts",
    meta: { layout: "header", title: "Контакты" },
    component: () => import("../views/landing"),
  },
  {
    path: "/auth/:type",
    name: "auth",
    meta: { layout: "header", title: "Авторизация" },
    component: () => import("../views/auth"),
  },
  {
    path: "/dashboard/:type?/:child?",
    name: "dashboard",
    meta: { layout: "header", title: "Панель управления" },
    component: () => import("../views/dashboard"),
    beforeEnter: haveToken,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("setLoading", 0);
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  next();
});

export default router;
